footer {
  margin-top: 4rem;
  color: white;
  .wrapper {
    background: black;
    .footer-section {
      margin-bottom: 30px;
      width: 50%;

      h2 {
        font-size: 14px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        margin-bottom: 5px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      a {
        display: block;
        margin-bottom: 10px;
        text-decoration: none;
        font-weight: normal;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.footer-container {
  background: black;
}
.footer-nav ul {
  padding: 30px 10px;
  margin: auto;
  background: black;
  li {
    a {
      color: white;
      margin-right: 1rem;
      text-decoration: none;
      font-size: 0.9rem;
      &:hover {
        text-decoration: underline;
      }
    }
    &:last-of-type a {
      margin-right: 0;
    }
  }
}

.footer-img {
  min-height: 154px;
  max-height: 350px;
}

.social {
  .social-icons {
    display: flex;
    align-items: center;
    .social-link {
      margin-right: 10px;
      width: 20px;
      height: auto;
      margin-bottom: 0;
      .social-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.copyright {
  margin-bottom: 15px;
  display: block;
  color: white;
  margin: 0;
  padding: 10px;
  background: black;
  p {
    font-size: 0.7rem;
  }
}
.legal-links {
  margin-top: 1rem;
  a {
    color: white;
    text-transform: capitalize;
    margin-right: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  footer {
    margin-top: 80px;
  }
  .copyright p {
    font-size: 0.8rem;
  }
  .footer-container.footer-nav {
    background: white;
  }

  .social .social-icons {
    margin-top: 40px;
  }
  .footer-nav {
    order: -1;
    ul {
      background: white;
      li {
        margin-right: 60px;
      }
      li a {
        color: black;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  footer {
    .wrapper {
      flex-wrap: wrap;

      .footer-section {
        margin-right: 20px;
        width: auto;
        order: 1;

        .social {
          margin: 0;
        }
        .copyright {
          margin-top: 0;
        }

        &:last-of-type {
          flex-basis: 100%;
          order: 3;
        }
      }
    }
  }
}
