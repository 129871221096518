.nav-drawer {
  width: 100%;
  .nav-drawer-content {
    width: 100vw;
    padding: 70px 50px;
    background: white;
    height: 100%;
    box-sizing: border-box;
    .menu-icon-close {
      position: absolute;
      top: 15px;
      right: 30px;
      margin-right: 0;
    }
  }
}

.nav-drawer-content a,
.desktop-navigation a {
  color: black;
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;

  header {
    background: white;
    box-sizing: border-box;
    height: 80px;
    padding: 0px 30px;
    box-shadow: 0px 0px 5px -1px rgba(161, 161, 161, 1);
    .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      .logo-container {
        width: auto;
        margin: auto;
        display: inline-block;
        order: 2;
        .logo {
          width: 196px;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .header-wrapper {
    header {
      .wrapper {
        justify-content: space-between;
        .logo-container {
          margin: 0;
          order: -1;
          .logo {
          }
        }
      }
    }
  }
  .desktop-navigation {
    display: flex;
    a {
      margin-right: 3rem;
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header-wrapper {
    header {
      padding: 0 80px;
      height: 100px;
    }
    #logo {
      width: 90px;
    }
  }
}
