.react-horizontal-scrolling-menu--wrapper {
  position: relative;
}

.img-card {
  width: 240px;
  height: 240px;
  margin-right: 1rem;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--item:first-child {
    margin-left: 2rem;
  }
  .react-horizontal-scrolling-menu--item:last-child {
    margin-left: 1rem;
  }
}

.scroller {
  padding: 4rem 0 1rem;
}

.slider-btn {
  width: 2rem;
  height: 2rem;
  background: black;
  color: white;
  position: absolute;
  z-index: 20;
  transition: 100ms ease-in;
  border: none;
  cursor: pointer;
  &.left {
    top: 45%;
    left: 0;
  }
  &.right {
    top: 45%;
    right: 0;
  }
  &:disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.triangle {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  &.triangle-right {
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #ffffff;
    left: 3px;
  }
  &.triangle-left {
    border-width: 9px 14px 10px 0;
    border-color: transparent #ffffff transparent transparent;
    right: 0;
  }
}

.slider-copy {
  text-align: center;
  padding: 4rem 1rem 20px;
  h2,
  h3,
  h4 {
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .slider-copy {
    text-align: center;
    padding: 4rem 2rem 3rem 2rem;
    h2,
    h3,
    h4 {
      position: relative;
      top: 20px;
    }
  }
}
