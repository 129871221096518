#age-gate {
  padding: 0px;
  background: black;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  z-index: 100000;
  &.error {
    background: red;
  }
  label {
    span {
      color: white;
    }
    span:nth-of-type(2) {
      margin-top: 3px;
    }
  }
  .age-gate-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    padding: 50px 25px;
    justify-content: flex-end;
    a {
      color: white;
      font-weight: bold;
    }
    .spanish-toggle {
      position: absolute;
      top: 0;
      right: 0;
    }
    .age-gate-logo {
      width: 100%;
      height: auto;
      margin-bottom: 40px;
      max-width: 200px;
    }
    h2 {
      margin: 20px 0px;
      font-size: 20px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
    }
    form {
      margin-bottom: 20px;
      line-height: 1;
      .error-message {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #age-gate {
    .age-gate-content {
      max-width: 500px;
      padding: 50px;
      height: calc(100% - 100px);
    }
  }
}
