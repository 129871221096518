.contain-img {
  display: block;
  max-width: 100%;
  width: 100%;
  object-fit: contain;
}

.cover-img {
  display: block;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.top {
  position: relative;
  z-index: 2;
}

.stretch {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.img-item {
  min-height: 350px;
}

.ig-img {
  margin: auto;
  width: 136px;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .ig-img {
    margin: auto;
    width: 191px;
  }
}

@media only screen and (min-width: 1000px) {
  .img-link {
    height: 3 89px;
  }
  .ig-img {
    margin-top: 40px;
  }
}
