.background-section {
  min-height: 469px;
  color: white;
  padding: 2rem;
  display: flex;
  align-items: flex-end;
  .container {
    margin: 0;
    max-width: 400px;
  }
}

.four-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.grid-cols {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.grid-cols-bigger {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 50px;
  max-width: 1280px;
  margin: auto;
}

.three-col {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

@media only screen and (min-width: 768px) {
  .background-section {
    align-items: center;
    justify-content: flex-end;
  }

  .two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    .text-content p:first-of-type {
      margin-top: 0;
    }
  }

  .three-col {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .four-col {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .grid-cols {
    grid-gap: 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .three-col {
    grid-gap: 60px;
  }
  .four-col {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.opp-blocks {
  .opp-block-img {
    margin-bottom: 50px;
  }
  h2,
  h3,
  h4 {
    margin-bottom: 25px;
  }
}
.black {
  background: black;
  color: white;
}

.silver {
  color: black;
  background: #f0f0f0;
}

.black,
.silver {
  padding: 20px 20px 40px 20px;
  max-width: 360px;
  p {
    max-width: 500px;
    margin: auto;
  }
  h3 {
    font-size: 20px;
  }
}
