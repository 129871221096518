.Button {
  border-radius: 0;
}

.black-border {
  border: 1px solid black;
  text-transform: uppercase;
  padding: 15px 30px 10px;
  text-decoration: none;
  color: black;
  transition: 300ms ease;
  &:hover,
  &:focus {
    background: black;
    color: white;
  }
}
