body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "AcuminRegular";
  font-weight: normal;
  letter-spacing: 1.2px;
}
#app-body {
  min-height: 100vh;
}
.loading-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: white;
  .circ-progress {
    color: black;
    margin-top: 120px;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  overflow: auto;
  overflow: visible;
}
.block {
  display: block !important;
}
.mr-5 {
  margin-right: 5px;
}
.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40:not(:last-child) {
  margin-bottom: 40px;
}

.mr-5 {
  margin-right: 10px;
}
.mr-10 {
  margin-right: 20px;
}
.mr-15 {
  margin-right: 30px;
}
.mr-20 {
  margin-right: 40px;
}

.ml-5 {
  margin-left: 10px;
}
.ml-10 {
  margin-left: 20px;
}
.ml-15 {
  margin-left: 30px;
}
.ml-20 {
  margin-left: 40px;
}

.text-content span {
  width: 100%;
  display: inline-block;
}

.text-content a {
  color: inherit;
  text-decoration: underline;
}
.center-align {
  text-align: center;
}
.right-align {
  text-align: right;
}

.mt-mb-30 {
  margin: 30px 0;
}
input,
textarea,
select {
  box-shadow: none !important;
  outline: none;
}
input {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.button {
  white-space: nowrap;
}
img {
  display: block;
}

ul {
  padding-left: 0;
  list-style: none;
}

.relative {
  position: relative;
}
.logo {
  max-width: 150px;
  @media only screen and (min-width: 768px) {
    max-width: 225px;
  }
}

#logo {
  width: 70px;
}

.order-1 {
  order: -1;
}

.padding {
  padding: 4rem 2rem 1rem 2rem;
}

.padding2 {
  padding: 4rem 2rem;
}
.padding3 {
  padding: 4rem 1rem;
}
.margin {
  margin: 4rem 0 0;
}

.container {
  max-width: 1440px;
  margin: auto;
}

.grid-2-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.grid-4-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-start-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.border {
  border: 1px solid black;
}

.accessibility {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  span {
    color: white;
  }
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .mb-40:not(:last-child) {
    margin-bottom: 0;
  }
  .accessibility {
    padding-top: 20px;
    flex-direction: row;
    justify-content: center;
  }
  .sm-legal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .sm-legal-content {
      display: flex;
      justify-content: center;
      .accessibility {
        padding-top: 40px;
        margin-left: 30px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .grid-4-col {
    grid-template-columns: repeat(4, 1fr);
  }
  .padding {
    padding: 80px 80px 50px 80px;
  }
  .padding2,
  .padding3 {
    padding: 80px;
  }
}
