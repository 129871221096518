@font-face {
  font-family: "AcuminBoldIt";
  src: url("../fonts/Acumin-BdItPro.otf") format("opentype");
}
@font-face {
  font-family: "AcuminBold";
  src: url("../fonts/Acumin-BdPro.otf") format("opentype");
}
@font-face {
  font-family: "AcuminIt";
  src: url("../fonts/Acumin-ItPro.otf") format("opentype");
}
@font-face {
  font-family: "AcuminRegular";
  font-weight: normal;
  src: url("../fonts/Acumin-RPro.otf") format("opentype");
}

html {
  font-size: 16px;
}

h1,
h2,
h3 {
  font-size: 2rem;
  letter-spacing: 3.2px;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
}
h5,
h6,
p {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
button span a {
  font-family: "AcuminRegular";
  font-weight: normal;
}

//Font Families
h1 {
  white-space: pre-wrap;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
}
h2,
h3,
h4 {
  white-space: pre-wrap;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
}

h5,
h6 {
  white-space: pre-wrap;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
}

p {
  letter-spacing: 1.35px;
}

ul li a,
.four-col-text p {
  font-family: "AcuminRegular";
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.53px;
  &:hover {
    text-decoration: underline;
  }
}

.no-underline-black {
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  p {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  .boldText h2,
  .boldText h3,
  .boldText h4 {
    font-size: 55px;
    margin-bottom: 1.5rem;
  }
}
