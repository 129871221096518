.hero-section {
  margin-top: 80px;
  width: 100%;
  height: 70vh;
  position: relative;

  .copy-wrapper {
    color: white;
  }
  .logo {
    margin-bottom: 2rem;
  }
}

.background-img {
  height: 70vh;
}

@media only screen and (min-width: 768px) {
  .hero-section {
    min-height: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .container {
      max-width: 400px;
      margin: 0;
    }
  }
}
