#age-gate {
  padding: 0px;
  background: black;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  z-index: 100000;
}

#age-gate.error {
  background: red;
}

#age-gate label span {
  color: white;
}

#age-gate label span:nth-of-type(2) {
  margin-top: 3px;
}

#age-gate .age-gate-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100% - 100px);
  padding: 50px 25px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

#age-gate .age-gate-content a {
  color: white;
  font-weight: bold;
}

#age-gate .age-gate-content .spanish-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

#age-gate .age-gate-content .age-gate-logo {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  max-width: 200px;
}

#age-gate .age-gate-content h2 {
  margin: 20px 0px;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

#age-gate .age-gate-content form {
  margin-bottom: 20px;
  line-height: 1;
}

#age-gate .age-gate-content form .error-message {
  display: block;
}

@media only screen and (min-width: 768px) {
  #age-gate .age-gate-content {
    max-width: 500px;
    padding: 50px;
    height: calc(100% - 100px);
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "AcuminRegular";
  font-weight: normal;
  letter-spacing: 1.2px;
}

#app-body {
  min-height: 100vh;
}

.loading-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: white;
}

.loading-screen .circ-progress {
  color: black;
  margin-top: 120px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

a {
  text-decoration: none;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  overflow: auto;
  overflow: visible;
}

.block {
  display: block !important;
}

.mr-5 {
  margin-right: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40:not(:last-child) {
  margin-bottom: 40px;
}

.mr-5 {
  margin-right: 10px;
}

.mr-10 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 30px;
}

.mr-20 {
  margin-right: 40px;
}

.ml-5 {
  margin-left: 10px;
}

.ml-10 {
  margin-left: 20px;
}

.ml-15 {
  margin-left: 30px;
}

.ml-20 {
  margin-left: 40px;
}

.text-content span {
  width: 100%;
  display: inline-block;
}

.text-content a {
  color: inherit;
  text-decoration: underline;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.mt-mb-30 {
  margin: 30px 0;
}

input,
textarea,
select {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: none;
}

input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.button {
  white-space: nowrap;
}

img {
  display: block;
}

ul {
  padding-left: 0;
  list-style: none;
}

.relative {
  position: relative;
}

.logo {
  max-width: 150px;
}

@media only screen and (min-width: 768px) {
  .logo {
    max-width: 225px;
  }
}

#logo {
  width: 70px;
}

.order-1 {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.padding {
  padding: 4rem 2rem 1rem 2rem;
}

.padding2 {
  padding: 4rem 2rem;
}

.padding3 {
  padding: 4rem 1rem;
}

.margin {
  margin: 4rem 0 0;
}

.container {
  max-width: 1440px;
  margin: auto;
}

.grid-2-col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.grid-3-col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.grid-4-col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.flex-col-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.flex-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.flex-start-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.border {
  border: 1px solid black;
}

.accessibility {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.accessibility span {
  color: white;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .mb-40:not(:last-child) {
    margin-bottom: 0;
  }
  .accessibility {
    padding-top: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .sm-legal-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .sm-legal-container .sm-legal-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .sm-legal-container .sm-legal-content .accessibility {
    padding-top: 40px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .grid-4-col {
    -ms-grid-columns: (1fr)[4];
        grid-template-columns: repeat(4, 1fr);
  }
  .padding {
    padding: 80px 80px 50px 80px;
  }
  .padding2,
  .padding3 {
    padding: 80px;
  }
}

@font-face {
  font-family: "AcuminBoldIt";
  src: url("../fonts/Acumin-BdItPro.otf") format("opentype");
}

@font-face {
  font-family: "AcuminBold";
  src: url("../fonts/Acumin-BdPro.otf") format("opentype");
}

@font-face {
  font-family: "AcuminIt";
  src: url("../fonts/Acumin-ItPro.otf") format("opentype");
}

@font-face {
  font-family: "AcuminRegular";
  font-weight: normal;
  src: url("../fonts/Acumin-RPro.otf") format("opentype");
}

html {
  font-size: 16px;
}

h1,
h2,
h3 {
  font-size: 2rem;
  letter-spacing: 3.2px;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
}

h5,
h6,
p {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
button span a {
  font-family: "AcuminRegular";
  font-weight: normal;
}

h1 {
  white-space: pre-wrap;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
}

h2,
h3,
h4 {
  white-space: pre-wrap;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
}

h5,
h6 {
  white-space: pre-wrap;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
}

p {
  letter-spacing: 1.35px;
}

ul li a,
.four-col-text p {
  font-family: "AcuminRegular";
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.53px;
}

ul li a:hover,
.four-col-text p:hover {
  text-decoration: underline;
}

.no-underline-black {
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  p {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1200px) {
  .boldText h2,
  .boldText h3,
  .boldText h4 {
    font-size: 55px;
    margin-bottom: 1.5rem;
  }
}

.nav-drawer {
  width: 100%;
}

.nav-drawer .nav-drawer-content {
  width: 100vw;
  padding: 70px 50px;
  background: white;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.nav-drawer .nav-drawer-content .menu-icon-close {
  position: absolute;
  top: 15px;
  right: 30px;
  margin-right: 0;
}

.nav-drawer-content a,
.desktop-navigation a {
  color: black;
  display: block;
  font-size: 18px;
  margin-bottom: 20px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.header-wrapper header {
  background: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 80px;
  padding: 0px 30px;
  -webkit-box-shadow: 0px 0px 5px -1px #a1a1a1;
          box-shadow: 0px 0px 5px -1px #a1a1a1;
}

.header-wrapper header .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.header-wrapper header .wrapper .logo-container {
  width: auto;
  margin: auto;
  display: inline-block;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.header-wrapper header .wrapper .logo-container .logo {
  width: 196px;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .header-wrapper header .wrapper {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .header-wrapper header .wrapper .logo-container {
    margin: 0;
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .desktop-navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .desktop-navigation a {
    margin-right: 3rem;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .header-wrapper header {
    padding: 0 80px;
    height: 100px;
  }
  .header-wrapper #logo {
    width: 90px;
  }
}

.contain-img {
  display: block;
  max-width: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.cover-img {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.top {
  position: relative;
  z-index: 2;
}

.stretch {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.img-item {
  min-height: 350px;
}

.ig-img {
  margin: auto;
  width: 136px;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .ig-img {
    margin: auto;
    width: 191px;
  }
}

@media only screen and (min-width: 1000px) {
  .img-link {
    height: 3 89px;
  }
  .ig-img {
    margin-top: 40px;
  }
}

.Button {
  border-radius: 0;
}

.black-border {
  border: 1px solid black;
  text-transform: uppercase;
  padding: 15px 30px 10px;
  text-decoration: none;
  color: black;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}

.black-border:hover, .black-border:focus {
  background: black;
  color: white;
}

.hero-section {
  margin-top: 80px;
  width: 100%;
  height: 70vh;
  position: relative;
}

.hero-section .copy-wrapper {
  color: white;
}

.hero-section .logo {
  margin-bottom: 2rem;
}

.background-img {
  height: 70vh;
}

@media only screen and (min-width: 768px) {
  .hero-section {
    min-height: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .hero-section .container {
    max-width: 400px;
    margin: 0;
  }
}

.Button {
  border-radius: 0;
}

.black-border {
  border: 1px solid black;
  text-transform: uppercase;
  padding: 15px 30px 10px;
  text-decoration: none;
  color: black;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}

.black-border:hover, .black-border:focus {
  background: black;
  color: white;
}

.background-section {
  min-height: 469px;
  color: white;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.background-section .container {
  margin: 0;
  max-width: 400px;
}

.four-col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.grid-cols {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.grid-cols-bigger {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(240px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 50px;
  max-width: 1280px;
  margin: auto;
}

.three-col {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  grid-gap: 20px;
}

@media only screen and (min-width: 768px) {
  .background-section {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .two-col {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
  .two-col .text-content p:first-of-type {
    margin-top: 0;
  }
  .three-col {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
  .four-col {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }
  .grid-cols {
    grid-gap: 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .three-col {
    grid-gap: 60px;
  }
  .four-col {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.opp-blocks .opp-block-img {
  margin-bottom: 50px;
}

.opp-blocks h2,
.opp-blocks h3,
.opp-blocks h4 {
  margin-bottom: 25px;
}

.black {
  background: black;
  color: white;
}

.silver {
  color: black;
  background: #f0f0f0;
}

.black,
.silver {
  padding: 20px 20px 40px 20px;
  max-width: 360px;
}

.black p,
.silver p {
  max-width: 500px;
  margin: auto;
}

.black h3,
.silver h3 {
  font-size: 20px;
}

.react-horizontal-scrolling-menu--wrapper {
  position: relative;
}

.img-card {
  width: 240px;
  height: 240px;
  margin-right: 1rem;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container .react-horizontal-scrolling-menu--item:first-child {
  margin-left: 2rem;
}

.react-horizontal-scrolling-menu--scroll-container .react-horizontal-scrolling-menu--item:last-child {
  margin-left: 1rem;
}

.scroller {
  padding: 4rem 0 1rem;
}

.slider-btn {
  width: 2rem;
  height: 2rem;
  background: black;
  color: white;
  position: absolute;
  z-index: 20;
  -webkit-transition: 100ms ease-in;
  transition: 100ms ease-in;
  border: none;
  cursor: pointer;
}

.slider-btn.left {
  top: 45%;
  left: 0;
}

.slider-btn.right {
  top: 45%;
  right: 0;
}

.slider-btn:disabled {
  opacity: 0;
  visibility: hidden;
}

.triangle {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}

.triangle.triangle-right {
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #ffffff;
  left: 3px;
}

.triangle.triangle-left {
  border-width: 9px 14px 10px 0;
  border-color: transparent #ffffff transparent transparent;
  right: 0;
}

.slider-copy {
  text-align: center;
  padding: 4rem 1rem 20px;
}

.slider-copy h2,
.slider-copy h3,
.slider-copy h4 {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .slider-copy {
    text-align: center;
    padding: 4rem 2rem 3rem 2rem;
  }
  .slider-copy h2,
  .slider-copy h3,
  .slider-copy h4 {
    position: relative;
    top: 20px;
  }
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
}

.map-wrapper .interactive-map {
  width: 100%;
  position: relative !important;
  display: block !important;
  height: 30vh !important;
}

.map-wrapper .locations-list-wrapper {
  display: block;
  height: calc(80vh - 100px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.map-wrapper .locations-list-wrapper.store-wrapper {
  padding: 2rem;
}

.map-wrapper .locations-list-wrapper .locations-list-title {
  margin: 0;
  padding: 0px 20px 0 0;
  color: black;
}

.map-wrapper .locations-list-wrapper .pagination-nav {
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #f0f0f0;
  color: black;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span {
  cursor: pointer;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span:nth-of-type(1) {
  margin-right: 10px;
}

.map-wrapper .locations-list-wrapper .info-message {
  background: black;
  color: white;
  padding: 8px 20px;
}

.map-wrapper .locations-list-wrapper .locations-search-form {
  padding: 10px 20px 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.map-wrapper .locations-list-wrapper .locations-list {
  background: white;
  overflow: scroll;
  height: 100%;
  position: relative;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location {
  padding: 20px;
  overflow: auto;
  border-bottom: 1px solid black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location:last-of-type {
  border-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location.active {
  background: #f0f0f0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid black;
  width: 100%;
  color: black;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: black;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .distance {
  font-size: 14px;
  line-height: 1.2;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .usage-type {
  font-size: 12px;
  font-weight: 900;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions {
  min-width: 110px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button {
  color: black;
  font-size: 10px;
  display: block;
  text-decoration: none;
  font-weight: 900;
  margin-bottom: 10px;
  white-space: nowrap;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  fill: black;
}

.title-mobile {
  display: block;
  text-align: center;
  padding: 4rem 2rem 1rem 2rem;
}

.title-desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  .map-wrapper .interactive-map {
    height: 45vh !important;
  }
}

@media only screen and (min-width: 1040px) {
  .map-wrapper {
    overflow: auto;
    padding: 80px 80px 50px 80px;
  }
  .map-wrapper .interactive-map {
    height: calc(80vh - 100px) !important;
    width: 60% !important;
    float: right;
    display: block;
  }
  .map-wrapper .stores-wrapper {
    height: calc(800vh - 100px) !important;
    width: 40%;
    float: left;
  }
  .map-wrapper .locations-list-wrapper.store-wrapper {
    padding: 0;
  }
  .info-message,
  .pagination-nav,
  .locations-list {
    width: 90%;
  }
  .title-mobile {
    display: none;
  }
  .title-desktop {
    display: block;
  }
}

#contact-page .wrapper {
  max-width: 700px;
  padding: 20px;
}

#contact-header {
  background: black;
  margin-top: 80px;
  width: 100%;
}

#contact-header h1 {
  text-align: center;
  padding: 40px 0px;
  color: white;
  margin: 0;
}

.form-message {
  display: block;
  background: black;
  color: white;
  padding: 20px;
  margin-bottom: 10px;
}

.form-message p {
  color: inherit;
  margin: 0;
}

.form-message a {
  text-decoration: underline;
  color: inherit;
}

.form-message.error {
  color: #ad0000;
  background: none;
  border: 1px solid #ad0000;
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

footer {
  margin-top: 4rem;
  color: white;
}

footer .wrapper {
  background: black;
}

footer .wrapper .footer-section {
  margin-bottom: 30px;
  width: 50%;
}

footer .wrapper .footer-section h2 {
  font-size: 14px;
  margin-bottom: 10px;
}

footer .wrapper .footer-section p {
  font-size: 14px;
  margin-bottom: 5px;
}

footer .wrapper .footer-section p:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section a {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  font-weight: normal;
}

footer .wrapper .footer-section a:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section:first-of-type, footer .wrapper .footer-section:last-of-type {
  width: 100%;
}

footer .wrapper .footer-section:last-of-type {
  margin-bottom: 0;
}

.footer-container {
  background: black;
}

.footer-nav ul {
  padding: 30px 10px;
  margin: auto;
  background: black;
}

.footer-nav ul li a {
  color: white;
  margin-right: 1rem;
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-nav ul li a:hover {
  text-decoration: underline;
}

.footer-nav ul li:last-of-type a {
  margin-right: 0;
}

.footer-img {
  min-height: 154px;
  max-height: 350px;
}

.social .social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.social .social-icons .social-link {
  margin-right: 10px;
  width: 20px;
  height: auto;
  margin-bottom: 0;
}

.social .social-icons .social-link .social-icon {
  width: 20px;
  height: 20px;
}

.copyright {
  margin-bottom: 15px;
  display: block;
  color: white;
  margin: 0;
  padding: 10px;
  background: black;
}

.copyright p {
  font-size: 0.7rem;
}

.legal-links {
  margin-top: 1rem;
}

.legal-links a {
  color: white;
  text-transform: capitalize;
  margin-right: 1rem;
}

@media only screen and (min-width: 768px) {
  footer {
    margin-top: 80px;
  }
  .copyright p {
    font-size: 0.8rem;
  }
  .footer-container.footer-nav {
    background: white;
  }
  .social .social-icons {
    margin-top: 40px;
  }
  .footer-nav {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .footer-nav ul {
    background: white;
  }
  .footer-nav ul li {
    margin-right: 60px;
  }
  .footer-nav ul li a {
    color: black;
  }
}

@media only screen and (min-width: 900px) {
  footer .wrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  footer .wrapper .footer-section {
    margin-right: 20px;
    width: auto;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  footer .wrapper .footer-section .social {
    margin: 0;
  }
  footer .wrapper .footer-section .copyright {
    margin-top: 0;
  }
  footer .wrapper .footer-section:last-of-type {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
