#contact-page {
  .wrapper {
    max-width: 700px;
    padding: 20px;
  }
}

#contact-header {
  background: black;
  margin-top: 80px;
  width: 100%;
  h1 {
    text-align: center;
    padding: 40px 0px;
    color: white;
    margin: 0;
  }
}

.form-message {
  display: block;
  background: black;
  color: white;
  padding: 20px;
  margin-bottom: 10px;
  p {
    color: inherit;
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: inherit;
  }
  &.error {
    color: $errorRed;
    background: none;
    border: 1px solid $errorRed;
  }
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}
